import axios from 'axios';
import { getToken } from 'components/AuthProvider';
import { REST_API_BASE_URL } from './configs';

const api = axios.create({
  baseURL: REST_API_BASE_URL,
});

api.interceptors.request.use(
  async (config) => {
    if (config && config.headers) {
      config.headers['Authorization'] = `Token ${getToken()}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
