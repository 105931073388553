import { css } from '@emotion/css';
import { Outlet } from 'react-router-dom';

export const PublicLayout = () => {
  return (
    <div
      className={css`
        min-height: 100vh;
      `}
    >
      <Outlet />
    </div>
  );
};
