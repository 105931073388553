export const APP_PAGES = {
  HOME: '/',
  CATEGORY: '/category',
  CATEGORIES: '/categories',
  BLOGS: '/blogs',
  BLOG: '/blog',
  PROJECTS: '/projects',
  MY_CREATIONS: '/my_creations',
  MY_CREATION: '/my_creation',
  PROJECT: '/project',
  SUBSCRIBERS: '/subscribers',
  LOGIN: '/auth/login',
};
