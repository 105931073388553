import api from 'api';
import { REST_API_BASE_URL } from 'api/configs';
import axios from 'axios';
import React, { createContext, useCallback, useContext, useMemo, useEffect, PropsWithChildren, useState } from 'react';

const TOKEN_NAME = 'auth-token';

export const getToken = () => localStorage.getItem(TOKEN_NAME);
export const setToken = (token: string) => localStorage.setItem(TOKEN_NAME, token);
export const removeToken = () => localStorage.removeItem(TOKEN_NAME);

export interface User {
  id: string;
  email: string;
  full_name: string;
}

interface ContextState {
  isAuthenticated: boolean;
  user: User | null;
  login: (email: string, password: string) => Promise<User>;
  logout: () => void;
}

const initialContextState: ContextState = {
  isAuthenticated: !!getToken(),
  user: null,
  login: () => Promise.reject(),
  logout: () => removeToken(),
};

const AuthStateContext = createContext(initialContextState);

const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [state, setState] = useState(initialContextState);
  const { isAuthenticated, user } = state;

  const logout = useCallback<ContextState['logout']>(() => {
    removeToken();
    setState((prev) => ({ ...prev, user: null, isAuthenticated: false }));
  }, []);

  const loadUser = useCallback<() => Promise<User | void>>(() => {
    return api
      .get<User>('users/me/')
      .then(({ data }) => {
        setState((prev) => ({ ...prev, user: data, isAuthenticated: true }));

        return data;
      })
      .catch(() => {
        logout();
      });
  }, [logout]);

  const login = useCallback<ContextState['login']>(
    (email, password) =>
      axios.post(REST_API_BASE_URL + '/users/login/', { username: email, password: password }).then(({ data }) => {
        setToken(data.token);

        return loadUser().then((user) => {
          if (user) {
            return user;
          } else {
            throw new Error('unable to load user');
          }
        });
      }),
    [loadUser]
  );

  useEffect(() => {
    if (isAuthenticated && user === null) {
      loadUser();
    }
  }, [isAuthenticated, loadUser, user]);

  const dispatchContext = useMemo(() => ({ ...state, login, logout }), [login, logout, state]);
  return <AuthStateContext.Provider value={dispatchContext}>{children}</AuthStateContext.Provider>;
};

function useAuthState() {
  const state = useContext(AuthStateContext);

  if (typeof state === 'undefined') {
    throw new Error('useAuthState must be used within a AuthProvider');
  }

  return state;
}

export { AuthProvider, useAuthState };
