import { LoadingOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { Spin } from 'antd';

export default function Spinner() {
  return (
    <div
      className={css`
        width: 100%;
        display: flex;
        justify-content: center;
      `}
    >
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
    </div>
  );
}
