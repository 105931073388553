import { useState } from 'react';
import { css } from '@emotion/css';
import { Button, Form, Input, Typography, Alert } from 'antd';
import Box from 'components/Box';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { APP_PAGES } from 'constants/pages';
import { useAuthState } from 'components/AuthProvider';

const initialValues = {
  email: '',
  password: '',
};

export default function Login() {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { isAuthenticated, login } = useAuthState();

  const handleSubmit = async (values: typeof initialValues) => {
    try {
      setError(null);
      await login(values.email, values.password);
    } catch (error: any) {
      if (error?.response?.data?.non_field_errors) {
        setError(error?.response?.data?.non_field_errors[0]);
      } else {
        setError('something went wrong');
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate(APP_PAGES.HOME);
    }
  }, [isAuthenticated, navigate]);

  return (
    <Box height={'100vh'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
      <Form
        initialValues={initialValues}
        onFinish={handleSubmit}
        className={css`
          width: 300px;
        `}
      >
        <Typography.Title
          className={css`
            text-align: center;
          `}
        >
          Login
        </Typography.Title>
        <Form.Item name={'email'} rules={[{ required: true }, { type: 'email' }]}>
          <Input placeholder="email" />
        </Form.Item>
        <Form.Item name={'password'} rules={[{ required: true }]}>
          <Input.Password placeholder="password" />
        </Form.Item>
        <Button
          htmlType="submit"
          type="primary"
          loading={loading}
          className={css`
            width: 100%;
          `}
        >
          Login
        </Button>
        {error && (
          <Alert
            type="error"
            message={error}
            className={css`
              margin-top: 16px;
            `}
          />
        )}
      </Form>
    </Box>
  );
}
