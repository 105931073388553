import 'antd/dist/reset.css';
import { ConfigProvider } from 'antd';
import { RouterProvider } from 'react-router-dom';
import { SWRConfig } from 'swr';
import { swrConfig } from 'configs/swr';
import { AuthProvider } from 'components/AuthProvider';
import { router } from './router';
import theme from './styles/theme';

function App() {
  return (
    <ConfigProvider theme={theme}>
      <SWRConfig value={swrConfig}>
        <AuthProvider>
          <RouterProvider router={router} />
        </AuthProvider>
      </SWRConfig>
    </ConfigProvider>
  );
}

export default App;
