import { Suspense, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Layout, Menu, MenuProps } from 'antd';
import {
  AuditOutlined,
  EditOutlined,
  HighlightOutlined,
  HomeOutlined,
  SendOutlined,
  TagsOutlined,
} from '@ant-design/icons';
import { css } from '@emotion/css';
import { APP_PAGES } from 'constants/pages';
import Header from './Header';
import { useAuthState } from 'components/AuthProvider';
import Spinner from 'components/Spinner';

const { Content, Sider } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

const menuItems: MenuItem[] = [
  { label: 'Home', key: APP_PAGES.HOME, itemIcon: <HomeOutlined /> },
  { label: 'Categories', key: APP_PAGES.CATEGORIES, itemIcon: <TagsOutlined /> },
  { label: 'Blogs', key: APP_PAGES.BLOGS, itemIcon: <HighlightOutlined /> },
  { label: 'Projects', key: APP_PAGES.PROJECTS, itemIcon: <AuditOutlined /> },
  { label: 'My Creations', key: APP_PAGES.MY_CREATIONS, itemIcon: <EditOutlined /> },
  { label: 'Subscribers', key: APP_PAGES.SUBSCRIBERS, itemIcon: <SendOutlined /> },
];

export default function PrivateLayout() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isAuthenticated } = useAuthState();

  useEffect(() => {
    if (isAuthenticated === false) {
      navigate(APP_PAGES.LOGIN);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <Header />
      <Layout>
        <Sider width={200}>
          <Menu
            mode="inline"
            defaultSelectedKeys={[APP_PAGES.HOME]}
            selectedKeys={[pathname]}
            items={menuItems}
            onSelect={({ key }) => navigate(key)}
            className={css`
              height: 100%;
            `}
          />
        </Sider>
        <Layout>
          <Content
            style={{
              padding: 24,
              margin: 0,
              minHeight: 'calc(100vh - 64px)',
            }}
          >
            <Suspense
              fallback={
                <div>
                  <Spinner />
                </div>
              }
            >
              <Outlet />
            </Suspense>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}
