import { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import PrivateLayout from 'components/layout/PrivateLayout';
import { APP_PAGES } from 'constants/pages';
import { PublicLayout } from 'components/layout/PublicLayout';
import Login from 'pages/Login';

const Home = lazy(() => import('pages/Home'));
const Blogs = lazy(() => import('pages/Blogs'));
const Blog = lazy(() => import('pages/Blog'));
const Projects = lazy(() => import('pages/Projects'));
const Project = lazy(() => import('pages/Project'));
const MyCreations = lazy(() => import('pages/MyCreations'));
const MyCreation = lazy(() => import('pages/MyCreation'));
const Category = lazy(() => import('pages/Category'));
const Categories = lazy(() => import('pages/Categories'));
const Subscribers = lazy(() => import('pages/Subscribers'));

export const router = createBrowserRouter([
  {
    path: '/',
    element: <PrivateLayout />,
    children: [
      {
        path: APP_PAGES.HOME,
        element: <Home />,
      },
      {
        path: APP_PAGES.BLOGS,
        element: <Blogs />,
      },
      {
        path: APP_PAGES.BLOG,
        element: <Blog />,
      },
      {
        path: APP_PAGES.BLOG + '/:id',
        element: <Blog />,
      },
      {
        path: APP_PAGES.PROJECTS,
        element: <Projects />,
      },
      {
        path: APP_PAGES.PROJECT,
        element: <Project />,
      },
      {
        path: APP_PAGES.PROJECT + '/:id',
        element: <Project />,
      },
      {
        path: APP_PAGES.MY_CREATIONS,
        element: <MyCreations />,
      },
      {
        path: APP_PAGES.MY_CREATION,
        element: <MyCreation />,
      },
      {
        path: APP_PAGES.MY_CREATION + '/:id',
        element: <MyCreation />,
      },
      {
        path: APP_PAGES.CATEGORIES,
        element: <Categories />,
      },
      {
        path: APP_PAGES.CATEGORY,
        element: <Category />,
      },
      {
        path: APP_PAGES.CATEGORY + '/:id',
        element: <Category />,
      },
      {
        path: APP_PAGES.SUBSCRIBERS,
        element: <Subscribers />,
      },
    ],
  },
  {
    path: '/auth',
    element: <PublicLayout />,
    children: [
      {
        path: APP_PAGES.LOGIN,
        element: <Login />,
      },
    ],
  },
]);
