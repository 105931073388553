import api from 'api';
import { SWRConfig } from 'swr';

const swrProps: (typeof SWRConfig)['defaultProps'] = {
  value: {
    fetcher: (resource, init) => {
      const promise = Array.isArray(resource) ? api(resource[0], resource[1]) : api(resource, init);

      return promise.then(({ data }) => data);
    },
  },
};

export const swrConfig = swrProps.value;
