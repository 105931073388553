import React, { ReactHTML, PropsWithChildren, CSSProperties } from 'react';
import { css } from '@emotion/css';

type Props = {
  component?: keyof ReactHTML;
} & CSSProperties;

const Box = (props: PropsWithChildren<Props>) => {
  const { component = 'div', children, ...cssProps } = props;

  const elementProps = {
    children,
    className: css(cssProps),
  };
  return React.createElement(component, elementProps);
};

export default Box;
