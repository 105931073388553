import { ThemeConfig } from 'antd/es/config-provider/context';

const theme: ThemeConfig = {
  token: {
    colorPrimary: '#F7BC4B',
  },
  components: {
    Typography: {
      fontSizeHeading1: 34,
      fontSizeHeading2: 26,
    },
    Layout: {
      colorBgHeader: '#363E4F',
    },
  },
};

export default theme;
