import { Button, Col, Layout, Row } from 'antd';
import { css } from '@emotion/css';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as AppLogo } from 'icons/appLogo.svg';
import { APP_PAGES } from 'constants/pages';
import Box from 'components/Box';
import { useAuthState } from 'components/AuthProvider';

export default function Header() {
  const { user, logout } = useAuthState();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate(APP_PAGES.LOGIN);
  };

  return (
    <Layout.Header>
      <Row justify={'space-between'}>
        <Col
          className={css`
            display: flex;
          `}
        >
          <Link
            to={'/'}
            className={css`
              display: flex;
              align-items: center;
              color: white;
            `}
          >
            <AppLogo />
          </Link>
        </Col>
        <Col>
          <Row>
            <Col>
              <Box color={'white'} padding={'0px 32px'}>
                {user ? user.email : 'loading'}
              </Box>
            </Col>
            <Col>
              <Button type="primary" danger onClick={handleLogout}>
                logout
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout.Header>
  );
}
